import cn from 'classnames'
import FooterSection from 'shared/components/layout/FooterSection'
import Header from '@shared/components/layout/Header'
import { ReactNode } from 'react'
import CustomImage from '../CustomImage'

type TemplateWithImageBannerProps = {
  additionalBannerClasses?: string
  additionalTextClasses?: string
  bannerText?: string
  children: ReactNode
  mainBanner?: string
  mobileBanner?: string
}

const TemplateWithImageBanner = (props: TemplateWithImageBannerProps) => {
  const { bannerText, children, additionalBannerClasses, additionalTextClasses, mainBanner, mobileBanner } = props

  return (
    <div>
      <Header />
      <div
        className={cn(
          'relative mt-20 flex h-[180px] w-full items-center justify-center font-monument-grotes lg:h-[345px] ',
          additionalBannerClasses,
        )}
      >
        {mainBanner && (
          <div className="relative hidden h-full w-full lg:block">
            <CustomImage
              src={mainBanner}
              fill
              placeholder="blur"
              blurDataURL="/images/default.png"
              className="object-cover"
              priority
              sizes="100vw"
            />
          </div>
        )}

        {mobileBanner && (
          <div className="relative h-full w-full lg:hidden">
            <CustomImage
              src={mobileBanner}
              fill
              placeholder="blur"
              blurDataURL="/images/default.png"
              className="object-cover"
              priority
              sizes="100vw"
            />
          </div>
        )}

        {bannerText && (
          <h1
            className={cn(
              'absolute w-[70%] text-center text-[40px] leading-[50px] text-white lg:w-auto lg:text-[90px] lg:leading-[70px]',
              additionalTextClasses,
            )}
          >
            {bannerText}
          </h1>
        )}
      </div>
      {children}
      <FooterSection />
    </div>
  )
}
export default TemplateWithImageBanner
