import { ChevronRightIcon } from '@heroicons/react/24/outline'
import Link from 'next/link'
import TemplateWithImageBanner from 'shared/components/layout/TemplateWithImageBanner'
type Props = {
  children: React.ReactNode
}
const NotFoundTemplate = ({ children }: Props) => {
  return (
    <>
      <TemplateWithImageBanner mainBanner="/images/404-banner.webp" mobileBanner="/images/404-banner.webp">
        <div className="flex w-full flex-col items-center gap-6 pb-10">
          {children}
          <nav className="grid grid-cols-1 gap-x-10 gap-y-5 px-7 md:grid-cols-2 md:gap-y-[30px]">
            <NavigationLink
              title="Find original art"
              description="Discover thousands of artworks curated just for you."
              urlText="Discover artworks"
              url="/discover"
            />
            <NavigationLink
              title="News and articles"
              description="Stay updated with the latest trends, stories, and insights in the art world."
              urlText="Read latest articles"
              url="/news"
            />
            <NavigationLink
              title="Are you an artist?"
              description="Join Cohart to showcase your work and reach buyers."
              urlText="Learn more"
              url="/for-artists"
            />
            <NavigationLink
              title="Are you an advisor?"
              description="Take your art business to the next level and grow your network."
              urlText="Learn more"
              url="/for-advisors"
            />
          </nav>
        </div>
      </TemplateWithImageBanner>
    </>
  )
}

type NavigationLinkProps = {
  title: string
  description: string
  urlText: string
  url: string
}

const NavigationLink = (props: NavigationLinkProps) => {
  const { title, description, urlText, url } = props

  return (
    <div className="flex w-full flex-col justify-between gap-3 rounded-[24px] border border-black/10 p-6 md:h-[164px] md:w-[376px] lg:px-10">
      <p className="text-lg font-medium md:text-2xl">{title}</p>
      <p className="text-xs md:text-base">{description}</p>

      <Link href={url} className="inline-flex items-center gap-2 text-[10px] text-[#0F6BFF] md:text-sm">
        <span>{urlText}</span>
        <ChevronRightIcon className="size-[18px]" />
      </Link>
    </div>
  )
}

export default NotFoundTemplate
