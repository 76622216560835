const USER_DASHBOARD = {
  HOMEPAGE: '/homepage',
  INVENTORY: '/artist/inventory',
  ARTWORKS: {
    UPLOAD: '/artworks/upload',
  },
  SALES: {
    INVOICES: {
      LIST: '/artist/invoices',
    },
    SALE_RECORDS: '/artist/sales',
    TESTIMONIALS: '/artist/testimonials',
  },
  CRM: {
    CONTACT: {
      LIST: '/crm/contacts',
    },
    GROUP: {
      LIST: '/crm/groups',
      DETAIL: '/crm/groups/[groupId]',
    },
    CAMPAIGN: {
      CREATE: '/crm/campaign/create',
      LIST: '/crm/campaign',
      DETAIL: '/crm/campaign/[campaignId]',
      EDIT: '/crm/campaign/[campaignId]/edit',
      RECIPIENTS: '/crm/campaign/[campaignId]/recipients',
    },
  },
  SETTINGS: {
    SUBSCRIPTION: '/settings/manage-plan',
  },

  COMMUNITY: '/artist/community',

  PROMOTION_CODE: {
    LIST: '/promotion-code',
    DETAIL: '/promotion-code/[promotionId]',
  },
}

const PUBLIC = {
  FAQS_ARTISTS: 'https://support.cohart.com/faqs-for-artists',
  NOT_FOUND: '/404',
  SUPPORT: {
    SHIPPING: 'https://support.cohart.com/shipping',
    LOCAL_SALES_TAX: 'https://support.cohart.com/local-sales-tax',
    MANAGE_PLAN: 'https://support.cohart.com/manage-plan',
    ARTIST_ONBOARDING_GUIDES: 'https://web.cohart.com/artist-onboarding-guides',
  },
}

export const ROUTES = {
  USER_DASHBOARD,
  PUBLIC,
}
